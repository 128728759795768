<template>
  <div id="shipping-list">
    <v-card>
      <v-card-title>出荷実績</v-card-title>
      <v-row class="px-2 ma-0">
        <v-col cols="12" md="3" sm="3">
          <v-autocomplete
            v-model="product_code"
            :items="deliveriesProductCodes"
            placeholder="商品コード"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3" sm="3">
          <v-autocomplete
            v-model="delv_status"
            :items="statuss"
            placeholder="出荷状態"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" md="3">
          <v-menu v-model="dateStartMenu" transition="scale-transition" offset-y min-width="auto" :nudge-right="40">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                placeholder="From"
                dense
                hide-details
                readonly
                outlined
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="startDate" @input="dateStartMenu = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="3">
          <v-menu
            v-model="dateEndMenu"
            :close-on-content-click="false"
            :nudge-left="60"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                placeholder="To"
                dense
                outlined
                hide-details
                readonly
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="endDate" @input="dateEndMenu = false"></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field v-model="search" placeholder="Search" outlined hide-details dense class="user-search me-3 mb-4">
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn color="secondary" outlined class="mb-4 me-3">
            <v-icon size="17" class="me-1">
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table :headers="headers" :items="deliveriesList" sort-by class="elevation-1" :search="search">
        <template v-slot:[`item.delv_id`]="{ item }">
          <div>
            <span>{{ item.delv_id }}</span>
          </div>
        </template>
        <template v-slot:[`item.id`]="{ item }">
          <a href="javascript:void(0);" @click="goOrderSelect(item)">{{ item.ord_id }}-{{ item.ord_detail_id }}</a>
        </template>
        <template v-slot:[`item.addr_name`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a
                v-bind="attrs"
                href="javascript:void(0);"
                :style="
                  item.mbr_status === 9
                    ? 'text-decoration: line-through red; color:#696969'
                    : 'text-decoration: underline; color:#696969'
                "
                v-on="on"
                >{{ item.addr_name }}</a
              >
            </template>
            <span>{{ item.addr_sei }} {{ item.addr_mei }}</span
            ><br />
            <span>{{ item.addr_sei_kana }} {{ item.addr_mei_kana }}</span
            ><br />
            <span>{{ item.addr_zip }}</span
            ><br />
            <span>{{ item.addr_addr1 }}{{ item.addr_addr2 }}</span
            ><br />
            <span>{{ item.addr_telno }}</span
            ><br />
          </v-tooltip>
        </template>
        <template v-slot:[`item.product_code`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a
                v-bind="attrs"
                href="javascript:void(0);"
                style="text-decoration: underline; color:#696969"
                v-on="on"
                >{{ item.product_code }}</a
              >
            </template>
            <span>{{ item.product_name }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.delv_status`]="{ item }">
          <div>
            <span>{{ getStatus(item.delv_status) }}</span>
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <TrackingNoDialog :id="item.delv_id" class="py-2 pl-1" @refresh="loadData"></TrackingNoDialog>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { mdiCalendar, mdiExportVariant } from '@mdi/js'
import TrackingNoDialog from '@/views/bimi/shipping/Shipping/TrackingNoDialog.vue'

export default {
  components: {
    TrackingNoDialog,
  },
  data: () => ({
    startWatch: false,
    startDate: '',
    endDate: '',
    dateEndMenu: '',
    dateStartMenu: '',
    product_code: '',
    search: '',
    delv_status: '',
    statuss: [],
    icons: {
      mdiCalendar,
      mdiExportVariant,
    },
    headers: [
      {
        text: 'ID',
        value: 'delv_id',
        align: 'left',
        width: '6%',
        fixed: false,
      },
      {
        text: '伝票番号',
        value: 'delv_slip_no',
        align: 'left',
        width: '15%',
        fixed: false,
      },
      {
        text: '送付先',
        align: 'left',
        sortable: true,
        value: 'addr_name',
        width: '14%',
        fixed: false,
      },
      {
        text: 'ID',
        value: 'ord_id',
        align: ' d-none',
      },
      {
        text: 'ID',
        value: 'ord_detail_id',
        align: ' d-none',
      },
      {
        text: '姓',
        value: 'addr_sei',
        align: ' d-none',
      },
      {
        text: '名',
        value: 'addr_mei',
        align: ' d-none',
      },
      {
        text: 'セイ',
        value: 'addr_sei_kana',
        align: ' d-none',
      },
      {
        text: 'メイ',
        value: 'addr_mei_kana',
        align: ' d-none',
      },
      {
        text: '商品コード',
        value: 'product_code',
        width: '20%',
        align: 'left',
        sortable: true,
        fixed: false,
      },
      {
        text: '数量',
        value: 'delv_qty',
        width: '10%',
        align: 'right',
        sortable: true,
        fixed: false,
      },
      {
        text: '追跡番号',
        value: 'tracking_no',
        width: '20%',
        align: 'left',
        sortable: true,
        fixed: false,
      },
      {
        text: '出荷状態',
        value: 'delv_status',
        width: '10%',
        align: 'left',
        sortable: true,
        fixed: false,
      },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        width: '5%',
        align: 'right',
        fixed: false,
      },
    ],
  }),
  computed: {
    ...mapState('shippingStore', ['deliveriesList', 'deliveriesProductCodes', 'deliverieyStatus']),
  },
  watch: {
    delv_status(newValue) {
      if (this.startWatch) {
        this.changeRouteQuery('delv_status', newValue)
        this.loadData()
      }
    },
    product_code(newValue) {
      if (this.startWatch) {
        this.changeRouteQuery('product_code', newValue)
        this.loadData()
      }
    },
    startDate(newValue) {
      if (this.startWatch) {
        this.changeRouteQuery('startDate', newValue)
        this.loadData()
      }
    },
    endDate(newValue) {
      if (this.startWatch) {
        this.changeRouteQuery('endDate', newValue)
        this.loadData()
      }
    },
  },
  created() {
    this.loadQuery()
    this.changeRouteQuery()
    this.loadData()
  },
  destroyed() {
    this.clearDeliveriesList()
    this.clearDeliveriesProductCodes()
    this.clearDeliveryStatusList()
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('shippingStore', ['loadDeliveriesList', 'loadDeliveryStatusList']),
    ...mapMutations('shippingStore', ['clearDeliveriesList', 'clearDeliveriesProductCodes', 'clearDeliveryStatusList']),
    goOrderSelect(item) {
      console.log(item)
      this.$router.push({
        name: 'order-by-member-list',
        query: {
          ord_id: item.ord_id,
        },
      })
    },

    loadData() {
      this.setOverlayStatus(true)
      Promise.all([this.loadDeliveryStatusList(), this.loadDeliveriesList(this.$route.query)])
        .then(() => {
          console.log('deliveriesList', this.deliveriesList)
          this.getStatusItem()

          // this.tableList = [...this.deliveriesList]
          // this.tableList.sort((a, b) => b.delv_id - a.delv_id)
        })
        .catch(error => {
          console.log('loadData', error)
        })
        .finally(() => {
          this.setOverlayStatus(false)
          this.startWatch = true
        })
    },

    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }

      this.$router.push({
        path: this.$route.path,
        query,
      })
    },

    loadQuery() {
      this.delv_status = this.$route.query.delv_status ?? ''
      this.product_code = this.$route.query.product_code ?? ''
      this.startDate = this.$route.query.startDate ?? ''
      this.endDate = this.$route.query.endDate ?? ''
    },

    getStatusItem() {
      this.statuss = this.deliverieyStatus.map(x => {
        const obj = {}
        obj.text = `${x.ds_code}: ${x.ds_desc}`
        obj.value = x.ds_code

        return obj
      })
    },

    getStatus(item) {
      for (let i = 0; i < this.deliverieyStatus.length; i += 1) {
        if (item === this.deliverieyStatus[i].ds_code) {
          return this.deliverieyStatus[i].ds_desc
        }
      }

      return ''
    },
  },
}
</script>
<style>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
.v-input__prepend-outer {
  margin: auto !important;
}
.text-field-dense-append-prepend-margin {
  margin: auto;
}
.v-input__append-inner {
  margin: auto !important;
}
/* .v-data-table tr:nth-child(odd) td {
  background-color: #F0FFFF;
} */
</style>
