var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"shipping-list"}},[_c('v-card',[_c('v-card-title',[_vm._v("出荷実績")]),_c('v-row',{staticClass:"px-2 ma-0"},[_c('v-col',{attrs:{"cols":"12","md":"3","sm":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.deliveriesProductCodes,"placeholder":"商品コード","outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.product_code),callback:function ($$v) {_vm.product_code=$$v},expression:"product_code"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.statuss,"placeholder":"出荷状態","outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.delv_status),callback:function ($$v) {_vm.delv_status=$$v},expression:"delv_status"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":"","min-width":"auto","nudge-right":40},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"placeholder":"From","dense":"","hide-details":"","readonly":"","outlined":"","prepend-icon":_vm.icons.mdiCalendar,"clearable":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateStartMenu),callback:function ($$v) {_vm.dateStartMenu=$$v},expression:"dateStartMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dateStartMenu = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-left":60,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"placeholder":"To","dense":"","outlined":"","hide-details":"","readonly":"","prepend-icon":_vm.icons.mdiCalendar,"clearable":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateEndMenu),callback:function ($$v) {_vm.dateEndMenu=$$v},expression:"dateEndMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dateEndMenu = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1)],1),_c('v-divider',{staticClass:"mt-4"}),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Search","outlined":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"secondary","outlined":""}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" "+_vm._s(_vm.icons.mdiExportVariant)+" ")]),_c('span',[_vm._v("Export")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.deliveriesList,"sort-by":"","search":_vm.search},scopedSlots:_vm._u([{key:"item.delv_id",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',[_vm._v(_vm._s(item.delv_id))])])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.goOrderSelect(item)}}},[_vm._v(_vm._s(item.ord_id)+"-"+_vm._s(item.ord_detail_id))])]}},{key:"item.addr_name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({style:(item.mbr_status === 9
                  ? 'text-decoration: line-through red; color:#696969'
                  : 'text-decoration: underline; color:#696969'),attrs:{"href":"javascript:void(0);"}},'a',attrs,false),on),[_vm._v(_vm._s(item.addr_name))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.addr_sei)+" "+_vm._s(item.addr_mei))]),_c('br'),_c('span',[_vm._v(_vm._s(item.addr_sei_kana)+" "+_vm._s(item.addr_mei_kana))]),_c('br'),_c('span',[_vm._v(_vm._s(item.addr_zip))]),_c('br'),_c('span',[_vm._v(_vm._s(item.addr_addr1)+_vm._s(item.addr_addr2))]),_c('br'),_c('span',[_vm._v(_vm._s(item.addr_telno))]),_c('br')])]}},{key:"item.product_code",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticStyle:{"text-decoration":"underline","color":"#696969"},attrs:{"href":"javascript:void(0);"}},'a',attrs,false),on),[_vm._v(_vm._s(item.product_code))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.product_name))])])]}},{key:"item.delv_status",fn:function(ref){
                  var item = ref.item;
return [_c('div',[_c('span',[_vm._v(_vm._s(_vm.getStatus(item.delv_status)))])])]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('TrackingNoDialog',{staticClass:"py-2 pl-1",attrs:{"id":item.delv_id},on:{"refresh":_vm.loadData}})],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }