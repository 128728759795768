<template>
  <div justify="center">
    <v-icon
      small
      @click.stop="openForm"
    >
      {{ icons.mdiPencil }}
    </v-icon>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card tile>
        <overlays
          :is-absolute="true"
          :is-show="absoluteOverlayStatus"
        ></overlays>
        <v-card>
          <template v-if="apierror.status == 'error'">
            <div v-for="msg in apierror.messages" :key="msg">
              <v-row class="ml-6 mb-3 ma-3">
                <span style="color: red">* {{ msg }} </span>
              </v-row>
            </div>
          </template>
          <v-card-title>
            <span
              class="text-h5"
              style="color: red"
            >
              追跡番号-編集
            </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row align="center" class="px-2 ma-0 my-2">
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="selectedTrackingNo.delv_id"
                    readonly
                    label="ID"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="selectedTrackingNo.devlv_slip_no"
                    readonly
                    label="伝票番号"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="$v.trackingNo.tracking_no.$model"
                    :error-messages="trackingNoErrors"
                    label="追跡番号"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-row align="center">
              <v-col
                cols="4"
              >
              </v-col>
              <v-col
                cols="2"
              >
                <v-btn
                  color="success"
                  :loading="submitStatus"
                  @click="submit(trackingNo)"
                >
                  YES
                </v-btn>
              </v-col>
              <v-col
                cols="2"
              >
                <v-btn
                  class="mx-2"
                  color="error"
                  @click="closeForm()"
                >
                  NO
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiPencil,
} from '@mdi/js'
import {
  required,
  maxLength,
} from 'vuelidate/lib/validators'
import {
  mapActions, mapState, mapMutations,
} from 'vuex'
import Overlays from '@/components/Overlays.vue'

export default {
  components: {
    Overlays,
  },
  props: ['id'],
  data: () => ({
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    dialog: false,
    submitStatus: false,
    icons: {
      mdiPencil,
    },
    trackingNo: {
      delv_id: 0,
      delv_slip_no: '',
      tracking_no: '',
    },
  }),
  validations: {
    trackingNo: {
      tracking_no: {
        required,
        maxLength: maxLength(16),
      },
    },
  },
  computed: {
    ...mapState('app', ['absoluteOverlayStatus']),
    ...mapState('shippingStore', ['selectedTrackingNo']),

    trackingNoErrors() {
      const errors = []
      if (!this.$v.trackingNo.tracking_no.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.trackingNo.tracking_no.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.trackingNo.tracking_no.maxLength && errors.push('16文字以内にしてください。')

      return errors
    },
  },
  methods: {
    ...mapMutations('app', ['setAbsoluteOverlayStatus', 'setOverlayStatus']),
    ...mapActions('shippingStore', ['loadTrackingNo', 'editTrackingNo']),
    openForm() {
      this.loadData()
    },
    closeForm() {
      this.dialog = false
      this.$emit('refresh')
    },
    loadData() {
      this.setOverlayStatus(true)
      this.loadTrackingNo(this.id).then(() => {
        // this.trackingNo = {
        //   ...this.selectedTrackingNo,
        // }
        this.trackingNo = this.$deepCopy(this.selectedTrackingNo)
      }).catch(error => {
        console.log(error.response)
        if (error.response) {
          this.apierror.status = 'error'
          this.apierror.code = error.response.data.code
          this.apierror.messages = error.response.data.message
        }
      }).finally(() => {
        this.dialog = true
        this.setOverlayStatus(true)
      })
    },

    submit(trackingNo) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.setAbsoluteOverlayStatus(true)
        this.submitStatus = true
        this.editTrackingNo(trackingNo)
          .then(() => {
            this.closeForm()
          })
          .catch(error => {
            console.log(error.response)
            if (error.response) {
              this.apierror.status = 'error'
              this.apierror.code = error.response.data.code
              this.apierror.messages = error.response.data.message
            }
          })
          .finally(() => {
            this.submitStatus = false
            this.setAbsoluteOverlayStatus(false)
          })
      } else {
        console.log('error submit!!')
      }
    },
  },
}
</script>
